// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Jose",
  middleName: "Rogelio",
  lastName: "Ibarra",
  role: "Technical Project Manager | Full-Stack Software Engineer | Agile Expert | UI/UX Designer",
  message: " BUILDING BOLD AND INNOVATIVE SOLUTIONS ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/MatrixSpock",
    },
    // {
    //   image: "fa-facebook",
    //   url: "https://www.facebook.com/MtrXSpoCK",
    // },
    // {
    //   image: "fa-instagram",
    //   url: "https://www.instagram.com/_joeibarra/",
    // },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/jose-r-ibarra/",
    },
    // {
    //   image: "fa-twitter",
    //   url: "https://www.twitter.com/JoJoeMtrx/",
    // },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/ProfilePic250px.jpeg"),
  imageSize: 375,
  message:
    "My name is Jose Ibarra. I have been a Software Professional since 2014. I am a Full-Stack Software Engineer and I have also served as a Project Manager, Database Administrator, Systems Administrator, and UI/UX Designer. My top skills are in JavaScript, Angular, C#, and T-SQL. I work with Enterprise applications following the MVC software design pattern.",
  resume: require("../editable-stuff/resume.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "MatrixSpock", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "As a Project Manager, I have served up to 9 clients at a given time and was responsible for end-to-end product delivery for the projects I managed. I believe project objectives should be carefully refined and simplified so that they are explicit and clear and focused on achieving the broader strategic vision of which that project is a part. I take full advantage of all my available resources and use my expertise to provide the most accurate and up-to-date information. I work to develop bold and innovative solutions all while taking ownership of each task and project I am in charge of. I lead with efficiency and communicate complex procedures in a clear and concise manner. I am diligent and strategic in my planning and I focus on continuous delivery.",
  images: [
    { 
      img: require("../editable-stuff/ProfilePic250px.jpeg"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/ProfilePic250px.jpeg"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Python", value: 75 },
    { name: "SQL", value: 90 },
    { name: "AngularJS", value: 85 },
    { name: "Angular 2-11", value: 90 },
    { name: "JavaScript", value: 90 },
    { name: "React", value: 65 },
    { name: "HTML/CSS", value: 90 },
    { name: "C#", value: 80 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 90 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 90 },
    { name: "Empathy", value: 90 },
    { name: "Organization", value: 90 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:"If you think I can be of assistance to you and your team, contact me and I'll be happy to help:",
  email: "jibarra8917@gmail.com",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Project Manager',// Here Add Company Name
      companylogo: require('../assets/img/dell.png'),
      date: 'Feb 2014 – Present',
      skills:'',
    },
    {
      role: 'Database Administrator',
      companylogo: require('../assets/img/boeing.png'),
      date: 'Feb 2014 – Present',
      skills:'',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
